import React from 'react';
import ReactDOM from 'react-dom';
import App, {route, group} from 'library/App';

function Main(){

    route('/@common/views/Home');
    route('/orders/:uuid/payment.html@common/views/Payment', 'orders@getSingle');
    route('/orders/:uuid/summary.html@common/views/Summary', 'orders@getSingle');
    route('/usage_terms.html@common/views/UsageTerms.js');

    route('/admin/login.html@admin/views/Login');

    group('admin', () => {

        route('/admin/home.html@admin/views/Home');
        route('/admin/orders/list.html@admin/views/Orders', 'orders@getAll');
        route('/admin/orders/add.html@admin/views/OrdersAdd.js');

        route('/admin/users/list.html@admin/views/UsersList', 'users@getAll', [

                route('/admin/users/:id/update.html@admin/views/UsersUpdate', 'users@getSingle'),
                route('/admin/users/add.html@admin/views/UsersAdd'),
                route('/admin/users/:id/summary.html@admin/views/UsersSummary', 'users@getSingle')
            ]
        );
    });

    return <App />;
}

ReactDOM.render(<Main />, document.getElementById('root'));
