import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useGroups(){

    const context = useContext(AppContext);

    function isMember(...groups){

        let isMember = false;

        context.groups.state.forEach(group => {

            if(groups.indexOf(group) !== null){

                isMember = true;
            }
        });

        return isMember;
    }

    return [context.groups.setGroups, isMember];
}

export default useGroups;
