export default function isAlphabetic(v){

    const chars = v.split('');
    const alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'å', 'ä', 'ö'];

    let alphabetic = true;

    chars.forEach(char => {
        
        if(alphabets.indexOf(char.toLowerCase()) === -1){

            alphabetic = false;
        }
    });

    return alphabetic;
}
