function useRouter(){

    const routes = [];

    let group = false;

    function setRoutes(mixed, dependencies, subroutes){

        if(dependencies === undefined || dependencies === null || dependencies === false || dependencies === ''){

            dependencies = [];
        }

        else if(Array.isArray(dependencies) === false){

            dependencies = [dependencies];
        }

        dependencies.forEach((v, k) => {

            const [object, method] = v.split('@');

            dependencies[k] = {object: object, method: method};
        });

        const [route, component] = mixed.split('@');

        const matched = [];

        if(subroutes !== undefined){

            subroutes.forEach(subroute => {

                routes.forEach((v, k) => {

                    if(v.path === subroute){

                        matched.push(v);
                        routes.splice(k, 1);
                    }
                });
            });
        }

        routes.push({group: group, path: route, component: component, dependencies: dependencies, subroutes: matched});

        return route;
    }

    function setGroup(name, callback){

        group = name;

        callback();

        group = false;
    }


    return [routes, setRoutes, setGroup];
}

export default useRouter;
