import parseRoutes from 'library/parseRoutes';
import request from 'library/request';

// Dynamically import modules and fetch dependencies by path

async function load(engine, path, options){

    if(options === undefined){

        options = {};
    }

    // By default cache is enabled

    if(options.cache === undefined){

        options.cache = true;
    }

    // By default autolad is enabled

    if(options.autoload === undefined){

        options.autoload = true;
    }

    // Get routes

    const router = parseRoutes(engine.current.routes, path);

    if(router === false){

        if(process.env.NODE_ENV === 'development'){

            console.error('Path not found:', path);
        }

        return Promise.reject({notFound: true});
    }

    // Get next side

    const [side] = router.routes[router.routes.length - 1].component.split('/');

    // Theme modules

    const theme = side + '@theme';
    
    if(engine.current.imports.get(theme) === undefined){

        engine.current.imports.set(theme, {from: 'themes/' + side + '.js'});
    }

    // Language modules (components)

    const translation = engine.current.lang + '@' + side;

    if(engine.current.imports.get(translation) === undefined){

        engine.current.imports.set(translation, {from: 'lang/' + side + '/' + engine.current.lang + '.json'});
    }

    // Check privileges for matched routes

    router.routes.forEach(route => {

        if(route.group !== false && engine.current.state.groups.indexOf(route.group) === -1){

            engine.current.setForbidden(path);
        }
    });

    if(engine.current.isForbidden(path) === false){

        router.routes.forEach(route => {

            // Route modules

            if(engine.current.imports.get(route.component) === undefined){

                engine.current.imports.set(route.component, {from: 'components/' + route.component}); // Add module location for component
            }

            // API modules

            route.dependencies.forEach(dependency => {

                if(engine.current.imports.get(dependency.object) === undefined){

                    engine.current.imports.set(dependency.object, {from: 'endpoints/' + side + '/' + dependency.object}); // Add module location for dependency
                }
            });
        });
    }

    // Load modules

    const modules = [];

    engine.current.imports.forEach((importable, k) => {

        // Skip module that is already loaded

        if(importable.module === undefined){

            modules.push(import(/* webpackChunkName: "[request]" */ '../' + importable.from).then(

                // Module loaded successfully

                module => {

                    importable.module = module.default; // Add imported module
                    engine.current.imports.set(k, importable);
                },

                // Loading of module failed

                error => {

                    engine.current.imports.delete(k); // Remove broken module from imports

                    return Promise.reject({error: error.message, network: true});
                }
            ));
        }
    });

    // After modules has been loaded fetch dependencies

    return Promise.all(modules).then(

        () => {

            // Skip loading dedendencies if autolad is disabled

            if(options.autoload === false){

                return Promise.resolve();
            }

            const dependencies = [];

            // Get most inner params from nested routes

            function getParams(routes){

                let params = false;

                routes.forEach(v => {

                    if(v.subroutes.length !== 0){

                        params = getParams(v.subroutes); 
                    }

                    params = v.params;
                });

                return params;
            }

            function isCached(path){
                
                return engine.current.cache.get(path) === undefined ? false : true;
            }

            const params = getParams(router.routes);
            const storeActions = [];
            const cachedPaths = [];

            let setCached = () => {};

            router.routes.forEach(route => {

                function getCachePath(path, params){

                    path.split('/').forEach(fragment => {

                        // If fragment begins with colon the fragment is parameter.

                        if(fragment.substr(0, 1) === ':'){

                            const param = fragment.substr(1, fragment.length -1);

                            const regex = new RegExp(':' + param, "g");

                            path = path.replace(regex, params[param]);
                        }
                    });

                    return path;
                }

                const cachePath = getCachePath(route.path, params);

                if(engine.current.isForbidden(path) === false){

                    // Refresh cache when requesting same path consecutive or if refresh cache is set true

                    if((path === engine.current.state.path && isCached(cachePath) === true) || options.cache === false){

                        // Get stores that are refreshed

                        const refresh = engine.current.cache.get(cachePath);

                        // Clear stores that will be refreshed

                        refresh.forEach(store => {

                            storeActions.push({store: side + '/' + store, type: 'clear'});
                        });

                        // Loop trouh cached paths

                        engine.current.cache.forEach((stores, path) => {

                            // Loop trough stores used by cached path

                            refresh.forEach(store => {

                                // Remove path from cache which contains refreshed store

                                if(stores.indexOf(store) !== -1){

                                    engine.current.cache.delete(path);
                                }
                            });
                        });
                    }

                    const cacheStores = [];

                    route.dependencies.forEach(dependency => {

                        cacheStores.push(dependency.object);

                        const imported = engine.current.imports.get(dependency.object);

                        if(path === engine.current.state.path || isCached(cachePath) === false || options.cache === false){

                            if(imported.module[dependency.method] === undefined){

                                if(process.env.NODE_ENV === 'development'){

                                    console.error('Method does not exist:', dependency.method, imported.module);
                                }
                            }

                            let dependencyOptions = imported.module[dependency.method];

                            // If dependency options is callback function call it with params

                            if(typeof dependencyOptions === 'function'){

                                dependencyOptions = dependencyOptions(params);
                            }

                            dependencies.push(request(dependencyOptions).then(

                                result => {

                                    // Update store

                                    if(result.data !== undefined){

                                        // Add to queue and run after all dependencies are resolved

                                        const store = side + '/' + dependency.object;
                                        const type = dependencyOptions.type === undefined ? 'update' : dependencyOptions.type;

                                        storeActions.push({store: store, type: type, result: result});
                                    }
                                },

                                error => {

                                    if(error.forbidden === true){

                                        engine.current.setForbidden(path);
                                    }

                                    return Promise.reject(error);
                                }
                            ));
                        }
                    });

                    cachedPaths.push({

                        path: cachePath,
                        stores: cacheStores
                    });

                    // Callback after all dependencies are resolved

                    setCached = () => {

                        cachedPaths.forEach(v => {

                            if(isCached(v.path) === false){                                

                                engine.current.cache.set(v.path, v.stores);
                            }
                        });
                    }
                }
            });

            return Promise.all(dependencies).then(

                () => {

                    // Clear and add actions

                    engine.current.setStores(storeActions);

                    setCached();

                    if(router.routes.length === 0){

                        return Promise.reject({notFound: true});
                    }

                    else {

                        return Promise.resolve();
                    }
                },

                error => {

                    return Promise.reject(error);
                }
            );
        },

        error => {

            console.error('Modules not resolved:', error);

            return Promise.reject(error);
        }
    );
}

export default load;
